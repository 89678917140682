import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import Layout from "@hoc/Layout"
import Title from "@components/Title"
import Paragraph from "@components/Paragraph"
import Link from "@components/Link"

const NotFoundPage = ({ data }) => {
  const backgroundImage = getImage(data.file.childImageSharp.gatsbyImageData);
  const bg = convertToBgImage(backgroundImage);

  return (
    <Layout locale={"en"}>
      <BackgroundImage {...bg} Tag="section" className="">
        <div className="min-h-[75vh] bg-black bg-opacity-70 w-full flex items-center justify-center flex-col text-center">
          <div className="w-11/12 mx-auto">
            <Title {...{
              type: 1,
              color: 'white',
              className: 'mb-2'
            }}>404 PAGE NOT FOUND</Title>
            <Paragraph {...{
              color: 'white',
              className: '!text-xl'
            }}>Sorry we couldn’t find what you’re looking for.</Paragraph>
            <Link {...{
              color: 'white',
              to: '/',
              type: 'button',
              className: 'mt-6 w-fit mx-auto'
            }}>Go home</Link>
          </div>
        </div>
      </BackgroundImage>
    </Layout>
  )
}

export default NotFoundPage

export const query = graphql`
  query ErrorPageQuery {
      file(relativePath: {eq: "share-your-journey-alt-2.jpg"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
`